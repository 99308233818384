<template>
  <div class="lessonview pl-2 pr-2 pb-4">

    CAN YU SEE THIS
    <div
      v-for="field in fieldList"
      :key="field.model"
      v-bind:class="field.model"
    >
      <div v-if="hasContent(field.model)">
        <dynamicfile
          v-if="field.model == 'main-session' && layoutType == 'file'"
          :url="loadFile()"
          class="w-full"
        ></dynamicfile>

        <div v-else class="w-full">
          <div
            v-if="showTitle(field.model)"
            class="flex pb-1 mt-5 mb-4 border-b-4 border-back-blue border-solid"
          >
            <h4 class="text-lg font-bold w-3/12">
              {{ setSectionTitle(field) }}
            </h4>

            <div class="w-9/12 flex justify-end" v-if="diagramsLoaded">
              <lessonResources :resources="documentFilter(field.model)">
              </lessonResources>
            </div>
          </div>

          <div class="flex flex-row">
            <dynamicfile
              v-if="field.model == 'main-session' && hasDocument"
              :url="loadFile()"
              class="w-full"
            ></dynamicfile>

            <div v-else v-bind:class="showDiagram(field.model) ? 'w-3/4' : ''">
              <div
                v-html="parseContent(field.model)"
                class="section-content text-justify"
              ></div>

              <div
                class="flex content-end mt-4 mb-3"
                v-show="hasPopupContent(field.model)"
              >
                <div
                  @click="showPopupScreen(field.model)"
                  class="
                    more-info
                    w-56
                    ml-0
                    p-2
                    text-center text-sm text-white
                    bg-orange-300
                    rounded
                    cursor-pointer
                    hover:bg-orange-500
                  "
                >
                  <i class="fas fa-arrow-alt-circle-right"></i> View
                  {{ popupTitle(field.model) }}
                </div>

                <div class="ml-4">
                  <lessonResources
                    :resources="documentFilter(field.model)"
                    :popupDown="true"
                  >
                  </lessonResources>
                </div>
              </div>

              <div
                class="video mb-8 mt-8"
                v-if="videosLoaded && showVideo(field.model)"
              >
                <iframe
                  class="sproutvideo-player"
                  v-bind:src="videoURL(field.model)"
                  frameborder="0"
                  scrolling="no"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div
              class="diagrams w-1/4 pl-8"
              v-if="diagramsLoaded"
              v-show="showDiagram(field.model)"
            >
              <div
                v-for="(pic, index) in diagramFilter(field.model)"
                :key="index"
                class="mb-2"
              >
                <dynamicimage
                  :url="loadDiagram(pic)"
                  :reference="'ref_' + index"
                  :name="pic.title"
                ></dynamicimage>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <popupScreen
      :title="selectedPopupTitle"
      :content="selectedPopupContent"
      :videos="selectedPopupVideos"
      :diagrams="selectedPopupDiagrams"
      @close="showPopupModal = false"
      v-if="showPopupModal"
      v-show="showPopupModal"
    ></popupScreen>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { SchoolService } from "@/services/school.service";

const dynamicimage = () => import("@/components/DynamicImage.vue");
const dynamicfile = () => import("@/components/DynamicFile.vue");
const popupScreen = () => import("@/components/LessonContentModal.vue");
const lessonResources = () =>
  import("@/components/curriculum/LessonResources.vue");

export default {
  name: "lessonview",
  props: {
    lesson: Object,
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
      settings: "settings",
    }),
    videosLoaded() {
      return this.videos.length > 0;
    },
    diagramsLoaded() {
      return this.resources.length > 0;
    },
    layoutType() {
      return this.lesson.layout.String;
    },
    hasDocument() {
      return (
        this.layoutType.length != 0 &&
        this.lesson.document_url.String.length > 3
      );
    },
    fieldList() {
      let values = this.settings.fields.filter((field) => {
        let filtered = true;
        filtered =
          field.used === true &&
          (field.popover === undefined || field.popover === false);
        if (filtered) {
          if (this.layoutType == "file") {
            filtered = field.filebased == true;
          }
        }

        return filtered;
      });
      return values;
    },
  },
  data() {
    return {
      errored: false,
      showPopupModal: false,
      videos: [],
      resources: [],
      selectedPopupTitle: "",
      selectedPopupContent: "",
      selectedPopupVideos: [],
      selectedPopupDiagrams: [],
    };
  },
  created() {},
  mounted() {
    this.fetchVideos();
    this.fetchResources();
  },
  methods: {
    fetchVideos() {
      SchoolService.getAll("lesson/" + this.lesson.id + "/videos")
        .then((response) => this.buildVideoList(response.data))
        .catch((err) => {
          console.log(" videos" + err);
          this.errored = true;
        })
        .finally(() => (this.errored = false));
    },
    buildVideoList(data) {
      this.videos = data;
    },
    fetchResources() {
      SchoolService.getAll("lesson/" + this.lesson.id + "/resources")
        .then((response) => this.buildResourceList(response.data))
        .catch((err) => {
          console.log(" resources" + err);
          this.errored = true;
        })
        .finally(() => (this.errored = false));
    },
    buildResourceList(data) {
      this.resources = data;
    },
    cancel() {
      this.$router.push({
        name: "UnitView",
        params: { courseId: this.lesson.course_id, title: this.title },
      });
      window.scrollTo(0, 0);
    },
    showTitle(section) {
      return section != "outline";
    },
    getContent(section) {
      let vm = this;
      var content = (function (field) {
        switch (field) {
          case "introduction":
            return vm.lesson.introduction.String + vm.getEquipment();
          case "warm-up":
            return vm.lesson.warmup.String;
          case "game-session":
            return vm.lesson.gamecontent.String;
          case "main-session":
            return vm.lesson.maincontent.String;
          case "competition":
            return vm.lesson.competition.String;
          case "conclusion":
            return vm.lesson.conclusion.String;
          case "activities":
            return vm.lesson.activities.String;
          case "health":
            return vm.lesson.health.String;
          case "performance":
            return vm.lesson.performance.String;
          case "collaboration":
            return vm.lesson.collaboration.String;
          default:
            return vm.lesson.outline.String;
        }
      })(section);
      return content;
    },
    parseContent(section) {
      let parsed = this.getContent(section).replace(/(\\r)*\\n/g, "<br>");
      return this.parseContentIcon(parsed).replace(/<p><br><\/p>/g, "");
    },
    parseContentIcon(content) {
      var parsed = content.replaceAll(
        "[[icon_info-circle]]",
        '<i class="fas fa-info-circle"></i>'
      );
      parsed = parsed.replaceAll(
        "[[icon_camera]]",
        '<i class="fas fa-camera"></i>'
      );
      parsed = parsed.replaceAll(
        "[[icon_video]]",
        '<i class="fas fa-video"></i>'
      );

      return parsed;
    },
    videoFilterList(section) {
      return this.videos.filter((v) => {
        return v.display_key == section;
      });
    },
    videoFilter(section) {
      return this.videoFilterList(section)[0];
    },
    diagramFilter(section) {
      return this.resources.filter((v) => {
        return v.display_key == section && v.format == "diagram";
      });
    },
    documentFilter(section) {
      return this.resources.filter((v) => {
        var filtered = true;

        filtered = v.format == "document";

        if (filtered) {
          if (section === "outline") {
            filtered = ["outline", "introduction"].includes(v.display_key);
          } else {
            filtered = v.display_key == section;
          }
        }

        return filtered;
      });
    },
    hasContent(section) {
      if (section === "main-session" && this.layoutType == "file") {
        return true;
      } else if (section === "main-session" && this.hasDocument) {
        return true;
      } else {
        return this.getContent(section).length != 0;
      }
    },
    hasPopupContent(section) {
      switch (section) {
        case "outline":
          return this.lesson.introduction.String.length > 0;
        case "main-session":
          return this.lesson.activities.String.length > 0;
        default:
          return false;
      }
    },
    popupTitle(section) {
      return section === "outline" ? "Introduction" : "Addtional Activities";
    },
    showPopupScreen(section) {
      let title =
        section === "outline" ? "Introduction" : "Addtional Activities";
      this.selectedPopupTitle = this.lesson.name + " " + title;

      let contentSection = section == "outline" ? "introduction" : "activities";
      this.selectedPopupContent = this.parseContent(contentSection);
      this.selectedPopupVideos = this.videoFilterList(contentSection);
      this.selectedPopupDiagrams = this.diagramFilter(contentSection);
      this.showPopupModal = true;
    },
    setSectionTitle(item) {
      return item.value == undefined ? item.name : item.value;
    },
    getEquipment() {
      let content =
        `<hr class="py-2"/>
        <h2  class="pt-2">Lesson Equipment</h2>
        <p>` +
        this.lesson.equipment.String +
        `</p>
        <h2>Lesson Location</h2>
        <p>` +
        this.lesson.location.String +
        `</p>`;
      return content;
    },
    showVideo(value) {
      return this.videoFilter(value) != undefined;
    },
    showDiagram(value) {
      return this.diagramFilter(value)[0] != undefined;
    },
    loadDiagram(diagram) {
      return diagram.folder + "/" + diagram.url;
    },
    loadFile() {
      return this.lesson.document_url.String;
    },
    videoURL(value) {
      let video = this.videoFilter(value);
      return (
        video.service_url_b.String +
        "?autoPlay=false&amp;playerTheme=dark&amp;playerColor="
      );
    },
  },
  components: {
    dynamicimage,
    dynamicfile,
    popupScreen,
    lessonResources,
  },
};
</script>

<style scoped>
.outline {
  background-color: #f6f6f6;
  display: block;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-top: 0px !important;
  margin-bottom: 2em !important;
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}

.outline .section-content {
  padding-left: 1.5em !important;
  padding-bottom: 0.5em;
  padding-right: 1.5em;
  padding-top: 0.5em;
  font-size: 1.1em;
  font-weight: 700;
}

.outline .more-info {
  @apply ml-6;
}

.video {
  width: 610px;
  height: 345px;
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  border: 2px solid #3e4448;
  background-color: #3e4448;
}

.video iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
}
</style>
